import { collection, doc, onSnapshot, setDoc } from "@firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "@firebase/storage";
import { useEffect } from "react";
import { useState } from "react";
import db from "../../firebase";

const AdminInfo = () => {
  const storage = getStorage();
  const [ data, setData ] = useState([]);
  const [ mainImage, setMainImage ] = useState();
  const [ mainImageShow, setMainImageShow ] = useState();

  useEffect(() => {
    onSnapshot(collection(db, "info"), (snapshot) => {
      setData(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))[0]
      );
    });
    getDownloadURL(ref(storage, `info\/main`)).then((results) => setMainImageShow(results)).catch((err)=>setMainImageShow())
  }, []);

  const toBase64 = (file) => new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      document.querySelectorAll("input[type='file']").forEach(el => el.value="");
    }
  });

  const sendData = async () => {
    if (mainImage) {
      let storageRef = ref(storage, `info/main`);
      await uploadBytes(storageRef, mainImage);
    }

    setDoc(doc(db, "info", data.id), {...data}).then(() => alert("Dane wysłano pomyślnie")).catch(err => console.error(err));
  }

  const handleMainImageInput = async (e) => {
    const file = Array.from(e.target.files)[0];
    let base64 = await toBase64(file);
    setMainImage(file);
    setMainImageShow(base64);
  }

  return <div className="admin-contact">
    <h4>Edytuj Informacje o nas</h4>
    {data && 
      <>
        <span>zdjęcie: </span><label><input type="file" id="files" title=" " style={{display: "none"}} onInput={handleMainImageInput}/><span style={{background: "#EEE", border: "1px solid gray", borderRadius: "4px", width: "70px", padding: "2px", fontSize: "0.8rem"}}>wybierz plik</span></label><br/>
        { mainImageShow && <><img src={mainImageShow} width="200px"/><br/></>}
        <span>tekst: </span><br/><textarea value={data.body} onInput={(e) => setData({...data, body: e.target.value})}></textarea><br/>
        <br/><input type="button" value="wyślij" onClick={sendData}/>
      </>
    }
  </div>;
}

export default AdminInfo;