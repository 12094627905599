import { collection, onSnapshot } from "@firebase/firestore";
import { getDownloadURL, getStorage, ref } from "@firebase/storage";
import { useState } from "react";
import { useEffect } from "react";
import db from "../../firebase";
import News from "../News/News";
import Player from "../Player";

const FrontPage = () => {
  const [ data, setData ] = useState();
  const [ mainImage, setMainImage ] = useState();
  const storage = getStorage();

  useEffect(() => {
    onSnapshot(collection(db, "frontpage"), (snapshot) => {
      setData(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))[0]
      );
    });
    getDownloadURL(ref(storage, `frontpage\/main`)).then((results) => {
      setMainImage(results)
    })
  }, [])

  return <>
    <Player url={mainImage} video={data?.video}/>
    <News />
  </>;
}

export default FrontPage