import { collection, doc, onSnapshot, setDoc } from "@firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import db from "../../firebase";

const AdminData = () => {
  const [ data, setData ] = useState([]);

  useEffect(() => {
    onSnapshot(collection(db, "data"), (snapshot) => {
      setData(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))[0]
      );
    });
  }, []);

  const sendData = async () => {
    setDoc(doc(db, "data", data.id), {...data}).then(() => alert("Dane wysłano pomyślnie")).catch(err => console.error(err));
  }

  return <div className="admin-contact">
    <h4>Edytuj informacje kontaktowe</h4>
    {data && 
      <>
        <span>tel: </span><input value={data?.tel} onInput={(e) => setData({...data, tel: e.target.value})}/><br/>
        <span>kom1: </span><input value={data?.kom1} onInput={(e) => setData({...data, kom1: e.target.value})}/><br/>
        <span>kom2: </span><input value={data?.kom2} onInput={(e) => setData({...data, kom2: e.target.value})}/><br/>
        <span>mail: </span><input value={data?.mail} onInput={(e) => setData({...data, mail: e.target.value})}/><br/>
        <span>linkedin: </span><input value={data?.linkedin} onInput={(e) => setData({...data, linkedin: e.target.value})}/><br/>
        <span>adres: </span><br/><textarea value={data?.adres} onInput={(e) => setData({...data, adres: e.target.value})}></textarea><br/>
        <span>praca: </span><br/><textarea value={data?.work} onInput={(e) => setData({...data, work: e.target.value})}></textarea><br/>
        <br/><input type="button" value="wyślij" onClick={sendData}/>
      </>
    }
  </div>;
}

export default AdminData;