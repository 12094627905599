import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import './NavBar.css';
import AnimateHeight from 'react-animate-height';

const NavBar = (props) => {
  const { contactOpen, toggleContact } = props;
  const [ projectOpen, setProjectOpen ] = useState(false);
  const [ aboutOpen, setAboutOpen ] = useState(false);
  const [ blackText, setBlackText ] = useState(false);
  const [ background, setBackground ] = useState(false);

  const location = useLocation();

  useEffect(()=>{
    window.addEventListener("scroll", (e) => {
      if( window.innerWidth < 800 ){ 
        if((e.target.scrollTop) > (window.innerHeight - 150)/2) setBackground(true);
        else setBackground(false);
      } else {
        if((e.target.scrollTop) > window.innerHeight - 150) setBackground(true);
        else setBackground(false);
      }
    }, true);
  }, [])
  
  if( background ) {
    if(!blackText) setBlackText(true)
  } else {
    if( (location.pathname === '/' || location.pathname.includes('/projekty/')) && blackText ) setBlackText(false);
    if( (location.pathname !== '/' && !location.pathname.includes('/projekty/')) && !blackText ) setBlackText(true);
  }

  useEffect(() => {
    if(location.pathname.includes("o_nas")) setAboutOpen(true)
    else setAboutOpen(false);

    if(location.pathname.includes("projekty") && !location.pathname.includes("/projekty/")) setProjectOpen(true)
    else setProjectOpen(false);
  }, [location.pathname])
  
  return (
    <nav className={`navbar ${blackText?"black-text":""} ${background?"background":""}`}>
      <div className="navbar-logo">
        <Link to="/">
          <img src="/Logo_2021 wybrane.png" alt="domagało wnuk architekci" className={`navbar-logo-image ${blackText?"black-text":""}`}/>
        </Link>
      </div>
      <div className={`navbar-separator ${blackText?"black-text":""}`}></div>
      <div className={`navbar-links ${(contactOpen || aboutOpen || projectOpen)?"gray-text":""}`}>
        <Link to="/o_nas" className={`white-text ${(!contactOpen && aboutOpen)?"black-text2":""}`}>O nas</Link>
        <Link to="/projekty" className={`white-text ${(!contactOpen && projectOpen)?"black-text2":""}`}>Projekty</Link>
        <a onClick={toggleContact} className={`white-text ${contactOpen?"black-text2":""} ${contactOpen && window.screen.width <= 800?"gray-text":""}`}>Kontakt</a>
      </div>
    </nav>
  );
}
  
export default NavBar;