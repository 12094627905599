import './Contact.css';
import db from "../../firebase";
import { useEffect } from 'react';
import { collection, onSnapshot } from '@firebase/firestore';
import {AiFillLinkedin} from 'react-icons/ai'
import { useState } from 'react';

const Contact = (props) => {
  const { isOpen, closeContact } = props;
  const [ contact, setContact ] = useState();

  useEffect(() => {
    onSnapshot(collection(db, "data"), (snapshot) => {
      setContact(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))[0]
      );
    });
  }, []);
  
  return (
    <>
      <div className={`contact ${isOpen ? "" : "contact-offscreen"}`}>
        <div className="contact-contact">
          <div className="bold">Kontakt:</div>
          tel. <a href={`tel:${contact?.tel}`}>{contact?.tel}</a> <br/>
          kom. <a href={`tel:${contact?.kom1}`}>{contact?.kom1}</a> | <a href={`tel:${contact?.kom2}`}>{contact?.kom2}</a><br/>
          e-mail: <a href={`mailto:${contact?.mail}`}>{contact?.mail}</a><br/>
          <a href={`https://pl.linkedin.com/company/${contact?.linkedin}`} target="_blank"><AiFillLinkedin style={{fontSize: "1.5rem", marginTop: "10px"}}/></a>
        </div>
        <div className="contact-address" style={{whiteSpace:"break-spaces"}}>
          <div className="bold">Pracownia:</div>
          {contact?.adres}
        </div>
        <div className="contact-work" style={{whiteSpace:"break-spaces"}}>
          <div className="bold">Praca:</div>
          { contact?.work }
        </div>
      </div>
      <div className={`contact-close-screen ${isOpen ? "" : "contact-offscreen"}`} onClick={closeContact}></div>
      <div className={`contact-close ${isOpen ? "" : "contact-offscreen"}`} onClick={closeContact}>X</div>
    </>
  );
}
  
export default Contact;

