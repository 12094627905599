import './Player.css';
import VimeoPlayer from '@u-wave/react-vimeo';
import { useEffect, useState } from 'react';
const video_id = 688995065;
// 79095e36d19b1f465b08a5be8fbc58e07f113a64

const Player = (props) => {
  const { url, video } = props;
  const bgUrl = `url(${url})`;
  // const [ thumbnail, setThumbnail ] = useState();

  return (
    <div className="video-spacer" style={{backgroundImage: bgUrl, backgroundSize: "cover", backgroundPosition: "center"}}>
      <div className="video-wrapper">
        { video &&
          <VimeoPlayer
            video={video}
            className="video"
            background
            width="100%"
            height="100%"
            autoplay
            responsive
            loop
          />
        }
      </div>
    </div>
  );
}
  
export default Player;

