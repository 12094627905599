import './About.css';
import Tile from '../UI';
import { useEffect, useRef, useState } from 'react';
import SimpleBarReact from "simplebar-react";
import db from "../../firebase";
import { collection, onSnapshot } from '@firebase/firestore';
import { getDownloadURL, getStorage, ref } from '@firebase/storage';

const studioText = 
<>Wykonywany przez nas zawód to przede wszystkim wielka pasja. Wierzymy,
że możemy komuś pomóc w tak ważnej dla niego materii jak przestrzeń, w
której żyje, pracuje. <br /><br />
Nasza architektura wynika zawsze z kontekstu i ducha miejsca. Wychodzimy
z założenia, że im większa liczba ograniczeń, tym finalny projekt będzie
lepszy, właściwie osadzony w przestrzeni. <br /><br />
Największą nagrodą w pracy jest zadowolenie klienta, któremu skutecznie
uda się rozwiązać problem, nierzadko w zaskakujący dla niego sposób.<br /><br />
Współpracujemy ściśle z doświadczonymi pracowniami branżowymi
(konstrukcyjną, elektryczną, sanitarną i drogową). Zajmujemy się
kompleksową obsługą inwestycji łącznie z reprezentowaniem inwestora
przed urzędami, aż do uzyskania ostatecznej decyzji o pozwoleniu na
budowę. <br /><br />
Nasze opracowania obejmują: <br />
koncepcje: architektoniczne, wielobranżowe, zagospodarowania terenu,
analizy chłonności terenu</>

const About = () => {
  const about = useRef(null);
  const scroll = useRef(null);
  const studio = useRef(null);
  const team = useRef(null);
  const awards = useRef(null);
  const storage = getStorage();
  const [ current, setCurrent ] = useState("studio");
  const [awardsData, setAwardsData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [teamImage, setTeamImage] = useState([]);
  const [info, setInfo] = useState();
  const [mainImage, setMainImage] = useState();
  const [ orderTeam, setOrderTeam ] = useState();
  const [ orderAwards, setOrderAwards ] = useState();

  useEffect(() => {
    onSnapshot(collection(db, "awards"), (snapshot) => {
      setAwardsData(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
    });
    onSnapshot(collection(db, "team"), (snapshot) => {
      setTeamData(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
    });
    onSnapshot(collection(db, "info"), (snapshot) => {
      setInfo(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))[0]
      );
    });
    getDownloadURL(ref(storage, `info\/main`)).then((results) => {
      setMainImage(results)
    });
    onSnapshot(collection(db, "order"), (snapshot) => {
      setOrderTeam(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})).find(v=>v.category=="team"))
      setOrderAwards(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})).find(v=>v.category=="awards"))
    });
  }, []);


  useEffect(async () => {
    let images = {};
    for (let project of teamData) {
      let response = await getDownloadURL(ref(storage, `${project.id}\/main`)).catch(err=>{console.error(err)});
      images[project.id] = response;
    }
    setTeamImage(images);
  }, [teamData])

  const scrollTo = (ref) => {
    // if(ref.current) ref.current.scrollIntoView(true)
    scroll.current.getScrollElement().scrollTop = ref.current.offsetTop - studio.current.offsetTop;
    // ref.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  const isOnScreen = (ref) => {
    return ref.current.getBoundingClientRect().top - (ref.current.getBoundingClientRect().top - ref.current.getBoundingClientRect().bottom)/2 >= 0;
  }

  const scrollHandler = (e) => {
    if (scroll.current.getScrollElement().scrollTop === scroll.current.getScrollElement().children[0].clientHeight - scroll.current.getScrollElement().clientHeight) {
      setCurrent("awards");      
    }else if (isOnScreen(studio)) {
      setCurrent("studio");
    } else if (isOnScreen(team)) {
      setCurrent("team");
    } else if (isOnScreen(awards)) {
      setCurrent("awards");
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler, true);
    return () => document.removeEventListener('scroll', scrollHandler, true);
  }, []);

  let orderedTeam = [];
  for (let i = 0; i < teamData.length; i++) {
    if(orderTeam && orderTeam.order && teamData.find(v => v.id === orderTeam.order[i]))
      orderedTeam.push(teamData.find(v => v.id === orderTeam.order[i]));
    else 
      orderedTeam.push(teamData[i])
  }

  let orderedAwards = [];
  for (let i = 0; i < awardsData.length; i++) {
    if(orderAwards && orderAwards.order && awardsData.find(v => v.id === orderAwards.order[i]))
      orderedAwards.push(awardsData.find(v => v.id === orderAwards.order[i]));
    else 
      orderedAwards.push(awardsData[i])
  }

  return (
    <div className="about" ref={about}>
      <div className="about-sidebar">
        <div className={`about-sidebar-item ${current === "studio"?"current":""}`} onClick={() => scrollTo(studio)}>Pracownia</div>
        <div className={`about-sidebar-item ${current === "team"?"current":""}`} onClick={() => scrollTo(team)}>Zespół</div>
        <div className={`about-sidebar-item ${current === "awards"?"current":""}`} onClick={() => scrollTo(awards)}>Nagrody</div>
      </div>
      <div className="about-content">
        <SimpleBarReact style={{ maxHeight: "100%" }} ref={scroll}>
          <div className="about-content-studio" ref={studio}>
            <Tile text={info?.body}/>
            <Tile img={mainImage} className="about-content-studio-image"/>
          </div>
          <div className="about-content-team" ref={team}>
            { orderedTeam.map((teamMember) => <Tile {...teamMember} img={teamImage[teamMember.id] || "pending"} imageRight key={teamMember.id}/>) }
          </div>
          <div className="about-content-awards" ref={awards}>
            { orderedAwards.map((award) => <Tile {...award} key={award.id} text={award.body} link={award.project}/>) }
          </div>
        </SimpleBarReact>
      </div>
    </div>
  );
}
  
export default About;

