import { useHistory } from 'react-router';
import './News.css';
import { useEffect, useState } from 'react';
import { collection, onSnapshot } from '@firebase/firestore';
import db from "../../firebase";
import { getDownloadURL, getStorage, ref } from '@firebase/storage';

const News = () => {
  const history = useHistory();
  const storage = getStorage();
  const [news, setNews] = useState([]);
  const [newsImage, setNewsImage] = useState([]);
  const [ order, setOrder ] = useState();

  useEffect(() => {
    onSnapshot(collection(db, "news"), (snapshot) => {
      setNews(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
    });
    onSnapshot(collection(db, "order"), (snapshot) => {
      setOrder(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})).find(v=>v.category=="news"))
    });
  }, []);

  useEffect(async () => {
    let images = {};
    for (let newsInstance of news) {
      let response = await getDownloadURL(ref(storage, `${newsInstance.id}\/main`)).catch(err=>{console.error(err)});
      images[newsInstance.id] = response;
    }
    setNewsImage(images);
  }, [news])

  let orderedData = [];
  for (let i = 0; i < news.length; i++) {
    if(order && order.order && news.find(v => v.id === order.order[i]))
      orderedData.push(news.find(v => v.id === order.order[i]));
    else 
      orderedData.push(news[i])
  }

  return (
    <div className="news">
    <div className="news-text">Nowości</div>
      <div className="news-bar">
        {orderedData.map((item) => 
          <div className="news-bar-item">
            <div className="news-bar-item-img-container">
              <img className="news-bar-item-img" src={newsImage[item.id]}/>
            </div>
            <div className="news-bar-item-top">
              <div className="news-bar-item-title">{item.title}</div>
              <div className="news-bar-item-year">{item.year}</div>
            </div>
            <div className="news-bar-item-text">
              {item.body}
            </div>
            {item.project && 
              <div className="news-bar-more pointer" onClick={() => history.push(`projekty/${item.project}`)}>
                Przejdź do projektu
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
}
  
export default News;