import { addDoc, collection, doc, onSnapshot, setDoc } from "@firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "@firebase/storage";
import { useEffect } from "react";
import { useState } from "react";
import db from "../../firebase";

const AdminNews = () => {
  const storage = getStorage();
  const [ news, setNews ] = useState([]);
  const [ newNews, setNewNews ] = useState([]);
  const [ mainImage, setMainImage ] = useState();
  const [ mainImageShow, setMainImageShow ] = useState();
  const [ order, setOrder ] = useState();

  useEffect(() => {
    onSnapshot(collection(db, "news"), (snapshot) => {
      setNews(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
    });
  }, []);

  useEffect(() => {
    if(news.length) {
      onSnapshot(collection(db, "order"), (snapshot) => {
        let o = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})).find(v=>v.category=="news");
        if(o && news && o.order.length && news.length && o?.order.length !== news?.length){
          o.order = news.map(v=>v.id);
        };
        setOrder(o)
      });
    }
  }, [news])

  const handleUp = (i) => {
    const swap = order.order[i];
    order.order[i] = order.order[i-1];
    order.order[i-1] = swap;
    setOrder({...order})
  }

  const handleDown = (i) => {
    const swap = order.order[i];
    order.order[i] = order.order[i+1];
    order.order[i+1] = swap;
    setOrder({...order})
  }

  const sendOrder = async () => {
    await setDoc(doc(db, "order", order.id), {order: order.order, category: order.category});
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      document.querySelectorAll("input[type='file']").forEach(el => el.value="");
    }
  });

  const handleMainImageInput = async (e) => {
    const file = Array.from(e.target.files)[0];
    let base64 = await toBase64(file);
    setMainImage(file);
    setMainImageShow(base64);
  }

  const sendnewNews = async () => {
    let id = newNews.id
    if(!id){
      id = (await addDoc(collection(db, "news"), newNews)).id;
      await setDoc(doc(db, "order", order.id), {order: [id ,...order.order], category: order.category});
    } else
      await setDoc(doc(db, "news", newNews.id), newNews);
      
    if (mainImage) {
      let storageRef = ref(storage, `${id}/main`);
      await uploadBytes(storageRef, mainImage);
    }
    alert("wysłano pomyślnie")
  }

  const handleSelectNews = (item) => {
    setMainImage();
    setMainImageShow();
    setNewNews(item);
    getDownloadURL(ref(storage, `${item.id}\/main`)).then((results) => setMainImageShow(results)).catch((err)=>setMainImageShow());
  }
  let orderedData = [];
  for (let i = 0; i < news.length; i++) {
    if(order && order.order && news.find(v => v.id === order.order[i]))
      orderedData.push(news.find(v => v.id === order.order[i]));
    else 
      orderedData.push(news[i])
  }

  return <div className="admin-contact">
    { orderedData && 
      <div className="admin-newNews">
        <h4>{ newNews.id?"Edytuj nowość":"Dodaj nową nowość"}</h4>
        { newNews.id && <><button onClick={()=>{setMainImageShow();setMainImage();setNewNews({title:"",body:"",year:"",projekt:"",url:""})}}>wróć do dodawania nowej</button><br/><br/></>}
        <span>tytuł: </span><input value={newNews.title} onInput={(e) => setNewNews({...newNews, title: e.target.value})}/><br/>
        <span>tekst: </span><br/><textarea value={newNews.body} onInput={(e) => setNewNews({...newNews, body: e.target.value})}></textarea><br/>
        <span>rok: </span><input value={newNews.year} onInput={(e) => setNewNews({...newNews, year: e.target.value})}/><br/>
        <span>projekt: </span><input value={newNews.project} onInput={(e) => setNewNews({...newNews, project: e.target.value})}/> (opcjonalne wkleić kod projektu)<br/>
        <span>zdjęcie: </span><label><input type="file" id="files" title=" " style={{display: "none"}} onInput={handleMainImageInput}/><span style={{background: "#EEE", border: "1px solid gray", borderRadius: "4px", width: "70px", padding: "2px", fontSize: "0.8rem"}}>wybierz plik</span></label><br/>
        { mainImageShow && <><img src={mainImageShow} width="200px"/><br/></>}
        <input type="button" value="wyślij" onClick={sendnewNews}/><br/>
        <table>
          <tr>
            <th>tytuł</th>
            <th>rok</th>
            <th>projekt</th>
            <th>edytuj</th>
            <th>w górę</th>
            <th>w dół</th>
          </tr>
          { orderedData?.map((row, i)=> 
            <tr>
              <td>{row.title}</td>
              <td>{row.year}</td>
              <td>{row.project}</td>
              <td><button onClick={() => handleSelectNews(row)}>edytuj</button></td>
              <td><button onClick={() => handleUp(i)} disabled={i==0}>w górę</button></td>
              <td><button onClick={() => handleDown(i)} disabled={i==orderedData.length-1}>w dół</button></td>
            </tr>
          ) }
        </table><br/>
        <input type="button" value="wyślij kolejność" onClick={sendOrder}/><br/>
      </div>
    }
  </div>;
}

export default AdminNews;