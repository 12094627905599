import { addDoc, collection, doc, onSnapshot, setDoc } from "@firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "@firebase/storage";
import { useEffect } from "react";
import { useState } from "react";
import db from "../../firebase";

const AdminAwards = () => {
  const [ awards, setAwards ] = useState([]);
  const [ newAwards, setNewAwards ] = useState([]);
  const [ order, setOrder ] = useState();

  useEffect(() => {
    onSnapshot(collection(db, "awards"), (snapshot) => {
      setAwards(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
    });
  }, []);
  useEffect(() => {
    if(awards.length) {
      onSnapshot(collection(db, "order"), (snapshot) => {
        let o = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})).find(v=>v.category=="awards");
        if(o && awards && o.order.length && awards.length && o?.order.length !== awards?.length){
          o.order = awards.map(v=>v.id);
        };
        setOrder(o)
      });
    }
  }, [awards])

  const handleUp = (i) => {
    const swap = order.order[i];
    order.order[i] = order.order[i-1];
    order.order[i-1] = swap;
    setOrder({...order})
  }

  const handleDown = (i) => {
    const swap = order.order[i];
    order.order[i] = order.order[i+1];
    order.order[i+1] = swap;
    setOrder({...order})
  }

  const sendOrder = async () => {
    await setDoc(doc(db, "order", order.id), {order: order.order, category: order.category});
  }

  const sendnewAward = async () => {
    let id = newAwards.id;
    if(id){
      await setDoc(doc(db, "awards", newAwards.id), newAwards);
    }else{
      id = (await addDoc(collection(db, "awards"), newAwards)).id;
      await setDoc(doc(db, "order", order.id), {order: [id ,...order.order], category: order.category});
    }   

  }

  const handleSelectNews = (item) => {
    setNewAwards(item);
  }

  let orderedData = [];
  for (let i = 0; i < awards.length; i++) {
    if(order && order.order && awards.find(v => v.id === order.order[i]))
      orderedData.push(awards.find(v => v.id === order.order[i]));
    else 
      orderedData.push(awards[i])
  }

  return <div className="admin-contact">
    { awards && 
      <div className="admin-newNews">
        <h4>{ newAwards.id?"Edytuj nagrodę":"Dodaj nową nagrodę"}</h4>
        { newAwards.id && <><button onClick={()=>setNewAwards({title:"",body:"",subtitle:""})}>wróć do dodawania nowej</button><br/><br/></>}
        <span>tytuł: </span><input value={newAwards.title} onInput={(e) => setNewAwards({...newAwards, title: e.target.value})}/><br/>
        <span>podtytuł: </span><input value={newAwards.subtitle} onInput={(e) => setNewAwards({...newAwards, subtitle: e.target.value})}/><br/>
        <span>tekst: </span><br/><textarea value={newAwards.body} onInput={(e) => setNewAwards({...newAwards, body: e.target.value})}></textarea><br/>
        <span>projekt: </span><input value={newAwards.project} onInput={(e) => setNewAwards({...newAwards, project: e.target.value})}/> (opcjonalne wkleić kod projektu)<br/>
        <input type="button" value="wyślij" onClick={sendnewAward}/><br/>
        <table>
          <tr>
            <th>tytuł</th>
            <th>podtytuł</th>
            <th>tekst</th>
            <th>projekt</th>
            <th>edytuj</th>
            <th>w górę</th>
            <th>w dół</th>
          </tr>
          { orderedData?.map((row, i)=> 
            <tr>
              <td>{row.title}</td>
              <td>{row.subtitle}</td>
              <td>{row.body}</td>
              <td>{row.project}</td>
              <td><button onClick={() => handleSelectNews(row)}>edytuj</button></td>
              <td><button onClick={() => handleUp(i)} disabled={i==0}>w górę</button></td>
              <td><button onClick={() => handleDown(i)} disabled={i==orderedData.length-1}>w dół</button></td>
            </tr>
          ) }
        </table><br/>
        <input type="button" value="wyślij kolejność" onClick={sendOrder}/><br/>
      </div>
    }
  </div>;
}

export default AdminAwards;