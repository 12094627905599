
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SimpleBarReact from "simplebar-react";
import './Tile.css';

const Tile = (props) => {
  const { img, title, subtitle, text, imageRight, className, onClick, styles, fill, link} = props;
  const history = useHistory();

  const [ height, setHeight ] = useState(28);

  useEffect(() => {

    const updateWindowDimensions = () => {
      if(window.innerWidth < 800) {
        setHeight(null);
      } else if(window.innerWidth < 1000) {
        setHeight("60vw");
      }  else {
        setHeight("28vw");
      } 
    };

    updateWindowDimensions();

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions) 
  }, []);

  return (
    <div className={`tile ${img?"":"tile-slim"} ${className} ${onClick?"pointer":""}`} onClick={onClick}>
      { img && <div className="tile-image" style={{backgroundImage: `url(${img})`, backgroundSize: "cover", backgroundPosition: `${imageRight?"right":"center"}`}}></div> }
      { title && <div className={`tile-title ${imageRight ? "image-right": ""}`}>{ link ? <span style={{cursor: "pointer"}} onClick={() => history.push(`projekty/${link}`)}>{ title }</span> : title }</div> }
      { subtitle && <div className={`tile-subtitle ${imageRight ? "image-right": ""}`}>{ subtitle }</div> }
      { text && <div className={`tile-text ${imageRight ? "image-right": ""}`}>{height?<SimpleBarReact style={{ maxHeight: height, whiteSpace: "break-spaces" }}>{ text }</SimpleBarReact>:<p style={{ maxHeight: height, whiteSpace: "break-spaces" }}>{text}</p>}</div> }
    </div>
  );
}
  
export default Tile;