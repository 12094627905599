import { addDoc, collection, doc, onSnapshot, setDoc } from "@firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "@firebase/storage";
import { useEffect } from "react";
import { useState } from "react";
import db from "../../firebase";

const categories = [
  "Nowe życie starych budynków",
  "Budynki projektowane",
  "Zagospodarowanie terenu",
  "Wnętrza",
]

const AdminProjects = () => {
  const [ newProject, setNewProject ] = useState({});
  const [ data, setData ] = useState();
  const [ images, setImages ] = useState([]);
  const [ imagesShow, setImagesShow ] = useState([]);
  const [ filter, setFilter ] = useState("");
  const [ mainImage, setMainImage ] = useState();
  const [ mainImageShow, setMainImageShow ] = useState();
  const [ miniImage, setMiniImage ] = useState();
  const [ miniImageShow, setMiniImageShow ] = useState();
  const [ vertical, setVertical ] = useState([]);
  const [ order, setOrder ] = useState([]);
  const [ newOrder, setNewOrder ] = useState();
  const [ imageOrder, setImageOrder ] = useState([]);
  const storage = getStorage();

  useEffect(() => {
    onSnapshot(collection(db, "projects"), (snapshot) => {
      setData(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
    });
    onSnapshot(collection(db, "order"), (snapshot) => {
      setOrder(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );

      setNewOrder(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})).find(v=>v.category==""))
    });
  }, []);

  if(filter) {
    if(newOrder && data && newOrder?.order?.length !== data?.filter(v=>v.category === filter)?.length)
      newOrder.order = data.filter(v=>v.category === filter).map(v=>v.id);
  } else {
    if(newOrder && data && newOrder?.order.length !== data?.length)
      newOrder.order = data.map(v=>v.id);
  }

  const getImageDimensions = (file) => {
    return new Promise (function (resolved, rejected) {
      var i = new Image()
      i.onload = function(){
        resolved({w: i.width, h: i.height})
      };
      i.src = file
    })
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      document.querySelectorAll("input[type='file']").forEach(el => el.value="");
    }
  });

  const handleSelectProject = async (item) => {
    document.getElementsByClassName("simplebar-content-wrapper")[0].scrollTo(0, 0);
    setImages([]);
    setImagesShow([]);
    setMainImage();
    setMainImageShow();
    setMiniImage();
    setMiniImageShow();
    setVertical([]);
    setNewProject(item);
    setImageOrder(item.order || item.vertical.map((v, i)=>i))
    if(item.numberOfImages){
      const imagesRef = [...(new Array(item.numberOfImages).keys())].map((image)=>ref(storage, `${item.id}\/images\/${image}`));
        
      Promise.all(imagesRef.map((ref)=> getDownloadURL(ref))).then((results) => {
        setImagesShow(results);
      }).catch(() => {
        setImagesShow([])
      })
    } else setImagesShow([]);
    getDownloadURL(ref(storage, `${item.id}\/main`)).then((results) => setMainImageShow(results)).catch((err)=>setMainImageShow())
    getDownloadURL(ref(storage, `${item.id}\/mini`)).then((results) => setMiniImageShow(results)).catch((err)=>setMiniImageShow())
  }

  const handleMainImageInput = async (e) => {
    const file = Array.from(e.target.files)[0];
    let base64 = await toBase64(file);
    setMainImage(file);
    setMainImageShow(base64);
  }

  const handleMiniImageInput = async (e) => {
    const file = Array.from(e.target.files)[0];
    let base64 = await toBase64(file);
    setMiniImage(file);
    setMiniImageShow(base64);
  }

  const handleImagesInput = async (e) => {
    if (images.length === 0 && imagesShow.length > 0) setImagesShow([]);
    let imagesTemp = [];
    let vertical = [];
    const files = Array.from(e.target.files);
    for (let file of files) {
      let base64 = await toBase64(file);
      var dimensions = await getImageDimensions(base64);
      imagesTemp.push(base64);
      vertical.push(dimensions.h > dimensions.w);
    }
    setImageOrder(vertical.map((v, i)=>i))
    setImages((np) => [...np, ...files]);
    setImagesShow((np) => [...np, ...imagesTemp]);
    setVertical((np) => [...np, ...vertical]);
  }

  const sendNewProject = async () => {
    if(!newProject.id){
      newProject.id = (await addDoc(collection(db, "projects"), {...newProject})).id;
      await setDoc(doc(db, "order", order.find(v=>v.category==="").id), {order: [newProject.id, ...order.find(v=>v.category==="").order], category: ""});
      if(newProject.category!=="")
        await setDoc(doc(db, "order", order.find(v=>v.category===newProject.category).id), {order: [newProject.id, ...order.find(v=>v.category===newProject.category).order], category: order.find(v=>v.category===newProject.category).category});
    }
    const numOfImages = images.length || newProject.numberOfImages;
    let verticalSet;
    if (vertical.length) verticalSet = vertical
    else verticalSet = newProject.vertical;
    if(images.length){
      for (let i = 0; i < images.length; i++) {
        let storageRef = ref(storage, `${newProject.id}/images/${i}`);

        await uploadBytes(storageRef, images[i]);
      }
    }
    if(mainImage) {
      let storageRef = ref(storage, `${newProject.id}/main`);
      await uploadBytes(storageRef, mainImage);
    }
    if(miniImage) {
      let storageRef = ref(storage, `${newProject.id}/mini`);
      await uploadBytes(storageRef, miniImage);
    }
    await setDoc(doc(db, "projects", newProject.id), {...newProject, code: newProject.title?.toLocaleLowerCase().replaceAll(" ", "-"), numberOfImages: numOfImages, vertical: verticalSet, order: imageOrder});
    
    alert("projekt wysłany");
    setMainImage();
    setMainImageShow();
    setMiniImage();
    setMiniImageShow();
    setImagesShow([]);
    setImages([]);
    setVertical([]);
    setNewProject({title:"",body:"",year:"",category:"", video: ""});
  }

  const sendOrder = async () => {
    await setDoc(doc(db, "order", newOrder.id), {order: newOrder.order, category: filter});
  }

  const handleUp = (i) => {
    const swap = newOrder.order[i];
    newOrder.order[i] = newOrder.order[i-1];
    newOrder.order[i-1] = swap;
    setNewOrder({...newOrder})
  }

  const handleDown = (i) => {
    const swap = newOrder.order[i];
    newOrder.order[i] = newOrder.order[i+1];
    newOrder.order[i+1] = swap;
    setNewOrder({...newOrder})
  }

  const handleUpImage = (i) => {
    const swap = imageOrder[i];
    imageOrder[i] = imageOrder[i-1];
    imageOrder[i-1] = swap;
    setImageOrder([...imageOrder])
  }

  const handleDownImage = (i) => {
    const swap = imageOrder[i];
    imageOrder[i] = imageOrder[i+1];
    imageOrder[i+1] = swap;
    setImageOrder([...imageOrder])
  }

  let orderedData = [];
  for (let i = 0; i < data?.filter(v=> !filter || v.category === filter).length; i++) {
    if(newOrder && newOrder.order && data.find(v => v.id === newOrder.order[i]))
      orderedData.push(data.find(v => v.id === newOrder.order[i]));
    else 
      orderedData.push(data[i])
  }

  return <div className="admin-projects">
    <h4>{ newProject.id?"Edytuj projekt":"Dodaj nowy projekt"}</h4>
    { newProject.id && <><button onClick={()=>{setMiniImage();setMiniImageShow();setMainImage();setMainImageShow();setImagesShow([]);setImages([]);setVertical([]);setNewProject({title:"",body:"",year:"",category:"", video: ""})}}>wróć do dodawania nowego</button><br/><br/></>}
    <span>tytuł: </span><input value={newProject.title} onInput={(e) => setNewProject({...newProject, title: e.target.value})}/><br/>
    <span>tekst: </span><br/><textarea value={newProject.body} onInput={(e) => setNewProject({...newProject, body: e.target.value})}></textarea><br/>
    <span>kategoria: </span> <select onInput={(e) => setNewProject({...newProject, category: e.target.value})} value={newProject.category}>
      {["", ...categories].map((category) => <option value={category}>{category}</option>)}
    </select><br/>
    
    <span>rok: </span><input value={newProject.year} onInput={(e) => setNewProject({...newProject, year: e.target.value})}/><br/>
    <span>filmik: </span><input value={newProject.video} onInput={(e) => setNewProject({...newProject, video: e.target.value})}/>(opcjonalnie ID filmiku z vimeo)<br/> 
    <span>miniatura: </span><label><input type="file" id="files2" title=" " style={{display: "none"}} onInput={handleMiniImageInput}/><span style={{background: "#EEE", border: "1px solid gray", borderRadius: "4px", width: "70px", padding: "2px", fontSize: "0.8rem"}}>wybierz plik</span></label><br/>
    { miniImageShow && <><img src={miniImageShow} width="200px"/><br/></>}
    <span>zdjęcie główne: </span><label><input type="file" id="files" title=" " style={{display: "none"}} onInput={handleMainImageInput}/><span style={{background: "#EEE", border: "1px solid gray", borderRadius: "4px", width: "70px", padding: "2px", fontSize: "0.8rem"}}>wybierz plik</span></label><br/>
    { mainImageShow && <><img src={mainImageShow} width="200px"/><br/></>}
    <span>zdjęcia: </span><label><input type="file" id="files3" title=" " multiple style={{display: "none"}} onInput={handleImagesInput}/><span style={{background: "#EEE", border: "1px solid gray", borderRadius: "4px", width: "70px", padding: "2px", fontSize: "0.8rem"}}>wybierz plik</span></label>
    { imagesShow && imagesShow.length > 0 && <button onClick={()=>{setImagesShow([]);setImages([]);setVertical([]);setNewProject({...newProject, images: []})}}>wyczyść zdjęcia</button>}<br/>
    { imagesShow && imagesShow.length > 0 && (
        <table>
          { imagesShow.map((image, i) => 
            <tr>
              <td><img src={imagesShow[imageOrder[i]]} width="200px" key="image"/></td>
              <td><button onClick={() => handleUpImage(i)} disabled={i==0}>w górę</button></td>
              <td><button onClick={() => handleDownImage(i)} disabled={i==imageOrder.length-1}>w dół</button></td>
            </tr>) 
          }
        </table>)}
    <br/><input type="button" value="wyślij" onClick={sendNewProject}/><br/>
    <span>filtruj: </span> 
    <select onInput={(e) => {setFilter(e.target.value); setNewOrder(order.find(v=>v.category===e.target.value))}} value={filter}>
      {["", ...categories].map((category) => <option value={category}>{category}</option>)}
    </select><br/>
    <table>
      <tr>
        <th>number</th>
        <th>tytuł</th>
        <th>rok</th>
        <th>kod</th>
        <th>kategoria</th>
        <th>edytuj</th>
        <th>w górę</th>
        <th>w dół</th>
      </tr>
      { orderedData.map((row, i)=> 
        <tr>
          <td>{i+1}</td>
          <td>{row.title}</td>
          <td>{row.year}</td>
          <td>{row.code}</td>
          <td>{row.category}</td>
          <td><button onClick={() => handleSelectProject(row)}>edytuj</button></td>
          <td><button onClick={() => handleUp(i)} disabled={i==0}>w górę</button></td>
          <td><button onClick={() => handleDown(i)} disabled={i==orderedData.length-1}>w dół</button></td>
        </tr>
      ) }
    </table><br/>
    <input type="button" value="wyślij kolejność" onClick={sendOrder}/><br/>

  </div>;
}

export default AdminProjects;