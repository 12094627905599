import { addDoc, collection, doc, onSnapshot, setDoc } from "@firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "@firebase/storage";
import { useEffect } from "react";
import { useState } from "react";
import db from "../../firebase";

const AdminTeam = () => {
  const storage = getStorage();
  const [ team, setTeam ] = useState([]);
  const [ newTeam, setNewTeam ] = useState([]);
  const [ mainImage, setMainImage ] = useState();
  const [ mainImageShow, setMainImageShow ] = useState();
  const [ order, setOrder ] = useState();

  useEffect(() => {
    onSnapshot(collection(db, "team"), (snapshot) => {
      setTeam(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
    });
  }, []);

  useEffect(() => {
    if(team.length) {
      onSnapshot(collection(db, "order"), (snapshot) => {
        let o = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})).find(v=>v.category=="team");
        if(o && team && o.order.length && team.length && o?.order.length !== team?.length){
          o.order = team.map(v=>v.id);
        };
        setOrder(o)
      });
    }
  }, [team])

  const handleUp = (i) => {
    const swap = order.order[i];
    order.order[i] = order.order[i-1];
    order.order[i-1] = swap;
    setOrder({...order})
  }

  const handleDown = (i) => {
    const swap = order.order[i];
    order.order[i] = order.order[i+1];
    order.order[i+1] = swap;
    setOrder({...order})
  }

  const sendOrder = async () => {
    await setDoc(doc(db, "order", order.id), {order: order.order, category: order.category});
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      document.querySelectorAll("input[type='file']").forEach(el => el.value="");
    }
  });

  const handleMainImageInput = async (e) => {
    const file = Array.from(e.target.files)[0];
    let base64 = await toBase64(file);
    setMainImage(file);
    setMainImageShow(base64);
  }

  const sendnewTeam = async () => {
    let id = newTeam.id
    if(!id) {
      id = (await addDoc(collection(db, "team"), newTeam)).id;
      await setDoc(doc(db, "order", order.id), {order: [id ,...order.order], category: order.category});
    }else
      await setDoc(doc(db, "team", newTeam.id), newTeam);    
      
    if (mainImage) {
      let storageRef = ref(storage, `${id}/main`);
      await uploadBytes(storageRef, mainImage);
    }
    alert("wysłano pomyślnie")
  }

  const handleSelectTeam = (item) => {
    setMainImage();
    setMainImageShow();
    setNewTeam(item);
    getDownloadURL(ref(storage, `${item.id}\/main`)).then((results) => setMainImageShow(results)).catch((err)=>setMainImageShow());
  }

  let orderedData = [];
  for (let i = 0; i < team.length; i++) {
    if(order && order.order && team.find(v => v.id === order.order[i]))
      orderedData.push(team.find(v => v.id === order.order[i]));
    else 
      orderedData.push(team[i])
  }

  return <div className="admin-contact">
    { team && 
      <div className="admin-newNews">
        <h4>{ newTeam.id?"Edytuj dane osoby":"Dodaj nową osobę"}</h4>
        { newTeam.id && <><button onClick={()=>{setMainImageShow();setMainImage();setNewTeam({title:"",text:"",subtitle:""})}}>wróć do dodawania nowej</button><br/><br/></>}
        <span>imię i nazwisko: </span><input value={newTeam.title} onInput={(e) => setNewTeam({...newTeam, title: e.target.value})}/><br/>
        <span>pozycja: </span><input value={newTeam.subtitle} onInput={(e) => setNewTeam({...newTeam, subtitle: e.target.value})}/><br/>
        <span>tekst: </span><br/><textarea value={newTeam.text} onInput={(e) => setNewTeam({...newTeam, text: e.target.value})}></textarea><br/>
        <span>zdjęcie: </span><label><input type="file" id="files" title=" " style={{display: "none"}} onInput={handleMainImageInput}/><span style={{background: "#EEE", border: "1px solid gray", borderRadius: "4px", width: "70px", padding: "2px", fontSize: "0.8rem"}}>wybierz plik</span></label><br/>
        { mainImageShow && <><img src={mainImageShow} width="200px"/><br/></>}
        <input type="button" value="wyślij" onClick={sendnewTeam}/><br/>
        <table>
          <tr>
            <th>imię i nazwisko</th>
            <th>pozycja</th>
            <th>edytuj</th>
            <th>w górę</th>
            <th>w dół</th>
          </tr>
          { orderedData?.map((row, i)=> 
            <tr>
              <td>{row.title}</td>
              <td>{row.subtitle}</td>
              <td><button onClick={() => handleSelectTeam(row)}>edytuj</button></td>
              <td><button onClick={() => handleUp(i)} disabled={i==0}>w górę</button></td>
              <td><button onClick={() => handleDown(i)} disabled={i==orderedData.length-1}>w dół</button></td>
            </tr>
          ) }
        </table><br/>
        <input type="button" value="wyślij kolejność" onClick={sendOrder}/><br/>
      </div>
    }
  </div>;
}

export default AdminTeam;