import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAScDVQGCQVLwKskwA9CD9hTfMj3dA9RYY",
  authDomain: "dwaarchstrona.firebaseapp.com",
  projectId: "dwaarchstrona",
  storageBucket: "dwaarchstrona.appspot.com",
  messagingSenderId: "297306281328",
  appId: "1:297306281328:web:d93b998376854b332cebb5",
  measurementId: "G-221C0BTT3G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
  
export default getFirestore();