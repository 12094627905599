import './Project.css';
import Tile from '../UI';
import { useEffect, useState } from 'react';
import { IoChevronBackOutline } from 'react-icons/io5'
import Player from '../Player';
import { useHistory, useParams } from 'react-router';
import { collection, onSnapshot, query, where } from '@firebase/firestore';
import db from "../../firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import SimpleBarReact from "simplebar-react";

const Project = () => {
  const history = useHistory();
  const [ num, setNum ] = useState(4);
  const [ height, setHeight ] = useState(18);
  const [ projectData, setProjectData ] = useState();
  const [ images, setImages ] = useState();
  const [ mainImage, setMainImage ] = useState();
  const { project } = useParams();
  const storage = getStorage();

  useEffect(() => {
    onSnapshot(query(collection(db, "projects"), where("code", "==", project)), (snapshot) => {

      setProjectData(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))[0]
      );
    });

  }, []);

  useEffect(() => {
    if(projectData){
      getDownloadURL(ref(storage, `${projectData.id}\/main`)).then((results) => {
        setMainImage(results)
      })

      if(projectData.numberOfImages > 0){
        const imagesRef = [...(new Array(projectData.numberOfImages).keys())].map((image)=>ref(storage, `${projectData.id}\/images\/${image}`))
        
        Promise.all(imagesRef.map((ref)=> getDownloadURL(ref))).then((results) => {
          setImages(results)
        })
      }
    }
  }, [projectData])

  useEffect(() => {
    const updateWindowDimensions = () => {
      if(window.innerWidth < 800) {
        setHeight(45);
      } else if(window.innerWidth < 1100) {
        setHeight(22.5);
      }  else {
        setHeight(18);
      }
      if(images){
        let vertChanged = projectData.vertical.map((v, i, arr) => arr[(projectData.order && projectData.order.length) ? projectData.order[i] : i])

        if(window.innerWidth < 800) {
          setNum(images.length+2 + vertChanged.reduce((bef, val)=>val?bef+1:bef, 0));
        }  else {
          const rows = Math.ceil((images.length + 1 + vertChanged.reduce((bef, val)=>val?bef+1:bef, 0))/2);
          let vert = 0, i = 0, j = 1;
          while(j < rows){
            if(vertChanged[i] && !vert) {vert = 1; i--}
            else vert = 0;
            j++;
            i++;
          }
          setNum(rows+vert);
        } 
      }
    };
    
    window.removeEventListener("resize", updateWindowDimensions) 

    updateWindowDimensions();

    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions) 
  }, [images])

  return (
    <>
      <Player url={mainImage} video={projectData?.video}/>
      <div className="project">
        <div className="back pointer" onClick={() => history.push("/projekty")}> <IoChevronBackOutline style={{marginRight: "25px"}}/>{ window.innerWidth > 800 ? projectData?.title : "Projekty"}</div>
        <div className="project-sidebar">
          <Tile className="w-auto" title={ `${projectData?.title} | ${projectData?.year}`} subtitle={ projectData?.category } />
        </div>
        <div className="project-content" style={{height: window.innerWidth < 800 ? "auto" : `calc(${num} * ( ${height}vw + 100px ))`}}>
          <p className="project-content-text"><SimpleBarReact style={{ maxHeight: "100%", whiteSpace: "break-spaces" }}>{ projectData?.body }</SimpleBarReact></p>
          { images?.map((image, i) => <div className={`project-content-image ${projectData.vertical[(projectData.order && projectData.order.length) ? projectData.order[i] : i]?"project-content-image-vert":""}`}><img src={images[(projectData.order && projectData.order.length) ? projectData.order[i] : i]} /></div>) }
        </div>
      </div>
    </>
  );
}
  
export default Project;

