import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import Player from './components/Player/Player';
import News from './components/News/News';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import About from './components/About';
import Contact from './components/Contact';
import { useEffect, useState } from 'react';
import Projects from './components/Projects';
import Project from './components/Project';
import ScrollToTop from './components/UI/ScrollToTop';
import SimpleBarReact from "simplebar-react";

import "simplebar/src/simplebar.css";
import Admin from './components/Admin';
import FrontPage from './components/FrontPage';
import { useLocation } from 'react-router-dom';

const App = () => {
  const [ isOpen, setIsOpen ] = useState(false);
  const location = useLocation();
  const [ showNavbar, setShowNavbar ] = useState(!location.pathname.includes("admin"));

  useEffect(() => {
    setShowNavbar(!location.pathname.includes("admin"));
  }, [location])

  const toggleContact = () => {
    setIsOpen(!isOpen);
  }

  const closeContact = () => {
    setIsOpen(false);
  }

  return (
      <div className="App">
        { showNavbar && <NavBar contactOpen={isOpen} toggleContact={toggleContact} />}
        <Contact  isOpen={isOpen} closeContact={closeContact} />
          <Switch>
            <Route path="/admin">
              <Admin />
            </Route>
            <Route path="/o_nas">
              <About />
            </Route>
            <Route path="/projekty" exact>
              <Projects />
            </Route>
            <Route path="/projekty/:project">
              <SimpleBarReact style={{ maxHeight: "100%" }}>
                <ScrollToTop />
                <Project />
              </SimpleBarReact>
            </Route>
            <Route path="/">
              <SimpleBarReact style={{ maxHeight: "100%" }}>
                <FrontPage />
              </SimpleBarReact>
            </Route>
          </Switch>
      </div>
  );
}

export default App;
