import './Projects.css';
import Tile from '../UI';
import { useState } from 'react';
import { useHistory } from 'react-router';
import SimpleBarReact from "simplebar-react";
import { useEffect } from 'react';
import { collection, onSnapshot } from '@firebase/firestore';
import db from "../../firebase";
import { getDownloadURL, getStorage, ref } from '@firebase/storage';
import ScrollToTop from '../UI/ScrollToTop';

const sideBar = [
  "Nowe życie starych budynków",
  "Budynki projektowane",
  "Zagospodarowanie terenu",
  "Wnętrza",
]

const Projects = () => {
  const history = useHistory();
  const storage = getStorage();
  const [ projectsData, setProjectsData ] = useState([]);
  const [ projectsImage, setProjectsImage ] = useState({});
  const [ current, setCurrent ] = useState("");
  const [ orders, setOrders ] = useState([]);
  const [ order, setOrder ] = useState([]);
  const [ orderedData, setOrderedData ] = useState([]);

  useEffect(() => {
    const cat = localStorage.getItem('category') || ""
    setCurrent(cat);
    localStorage.setItem('category', "")
    const scroll = localStorage.getItem('scroll') || 0
    localStorage.setItem('scroll', "")

    setTimeout(() => {
      document.getElementsByClassName("simplebar-content-wrapper")[0].style.scrollBehavior = "auto";
      document.getElementsByClassName("simplebar-content-wrapper")[0].scrollTop = scroll;
      document.getElementsByClassName("simplebar-content-wrapper")[0].style.scrollBehavior = "smooth";
    }, 10);

    onSnapshot(collection(db, "projects"), (snapshot) => {
      setProjectsData(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
    });
    onSnapshot(collection(db, "order"), (snapshot) => {
      setOrders(
        snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      );
      setOrder(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})).find(v=>v.category==cat))
    });
  }, []);

  useEffect(() => {
    if(projectsData.length && order) {
      const projectsDataCurrent = projectsData.filter((project) => !current || current === project.category);
      const projectsDataTile = projectsDataCurrent.map((project) => ({
        id: project.id,
        title: `${project.title}\xa0\xa0|\xa0\xa0${project.year}`,
        subtitle: "",
        link: project.title ? project.title.toLocaleLowerCase().replaceAll(" ", "-") : ""
      }));

      let ordered = [];
      for (let i = 0; i < projectsDataTile.length; i++) {
        if(order && order.order && projectsDataTile.find(v => v.id === order.order[i]))
          ordered.push(projectsDataTile.find(v => v.id === order.order[i]));
        else 
          ordered.push(projectsDataTile[i])
      }
      setOrderedData(ordered);
    }
  }, [projectsData, current, order]);

  // useEffect(async () => {
    // let images = {};
    // for (let project of orderedData) {
    //   let response = await getDownloadURL(ref(storage, `${project.id}\/mini`)).catch(err=>{console.error(err)});
    //   images[project.id] = response;
    //   setProjectsImage({...images});
    // }
  // }, [orderedData]);

  const handleSideBarClick = (sideBarItem) => {
    document.getElementsByClassName("simplebar-content-wrapper")[0].style.scrollBehavior = "auto";
    document.getElementsByClassName("simplebar-content-wrapper")[0].scrollTop = 0;
    document.getElementsByClassName("simplebar-content-wrapper")[0].style.scrollBehavior = "smooth";
    if (current === sideBarItem) {
      setCurrent("");
      setOrder(orders.find(v=>v.category==""))
    }
    else {
      setCurrent(sideBarItem);
      setOrder(orders.find(v=>v.category==sideBarItem))
    }
  }

  const handleProjectClick = (link) => {
    localStorage.setItem('category', current);
    localStorage.setItem('scroll', document.getElementsByClassName("simplebar-content-wrapper")[0].scrollTop);
    history.push(`projekty/${link}`)
  }  

  return (
    <div className="projects">
      <div className="projects-sidebar">
       { sideBar.map((sideBarItem) => 
         <div className={`projects-sidebar-item ${current === sideBarItem?"current":""}`} key={sideBarItem} onClick={() => handleSideBarClick(sideBarItem)} >{ sideBarItem }</div>
       ) }
      </div>
      <div className="projects-content">
        <SimpleBarReact style={{ height: "100%" }}>
          <div className="projects-content-images">
            { orderedData.map((projectTile, i) => <Tile key={projectTile.id + i} {...projectTile} img={`https://firebasestorage.googleapis.com/v0/b/dwaarchstrona.appspot.com/o/${projectTile.id}%2Fmini?alt=media` || "pending"} fill onClick={() => handleProjectClick(projectTile.link)} />) }
          </div>
        </SimpleBarReact>
      </div>
    </div>
  );
}
  
export default Projects;
