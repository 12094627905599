import './Admin.css';
import db from "../../firebase";
import { addDoc, collection, doc, onSnapshot, query, setDoc, where } from '@firebase/firestore';
import { getStorage, uploadBytes, ref , getDownloadURL} from "firebase/storage";
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import AdminData from './AdminData';
import AdminProjects from './AdminProjects';
import AdminNews from './AdminNews';
import AdminFrontPage from './AdminFrontPage';
import AdminAwards from './AdminAwards';
import AdminTeam from './AdminTeam';
import AdminInfo from './AdminInfo';
import SimpleBarReact from "simplebar-react";

const Admin = () => {
  const [ password, setPassword ] = useState("");
  const [ auth, setAuth ] = useState(false);
  const [ tab, setTab ] = useState(0);
  const [ data, setData ] = useState([]);
  const [ newNews, setNewNews ] = useState({});
  const [ images, setImages ] = useState([]);
  const history = useHistory();
  const storage = getStorage();

  const handlePassword = (e) => {
    e.preventDefault();
    onSnapshot(query(collection(db, "password"), where("password", "==", password)), (snapshot) => {
      if(snapshot.docs.length) setAuth(true);
      else setAuth(false)
    });
  }

  return (
    <div className="admin">
      <div className="admin-back"><button onClick={() => history.push('/')}>Powrót do strony</button></div>
      { !auth ?
        <div className="password-check">
          { auth === false && <>Błędne hasło<br/></> }
          <form>
            <input type="password" value={password} placeholder="wpisz hasło" onChange={(e) => setPassword(e.target.value)}/>
            <input type="submit" onClick={handlePassword} value="wyślij"/>
          </form>
        </div>
        :
          <div className="admin-content">
            <div className="admin-sidebar">
              <li onClick={() => setTab(4)}>strona główna</li>
              <li onClick={() => setTab(0)}>nowości</li>
              <li onClick={() => setTab(6)}>o nas</li>
              <li onClick={() => setTab(5)}>zespół</li>
              <li onClick={() => setTab(2)}>nagrody</li>
              <li onClick={() => setTab(1)}>projekty</li>
              <li onClick={() => setTab(3)}>kontakt</li>
            </div>
            <div className="admin-panel">
            <SimpleBarReact style={{ height: "100%", whiteSpace: "break-spaces" }}>
                { tab === 0 && <AdminNews />}
                { tab === 1 && <AdminProjects />}
                { tab === 2 && <AdminAwards /> }
                { tab === 3 && <AdminData /> }
                { tab === 4 && <AdminFrontPage /> }
                { tab === 5 && <AdminTeam /> }
                { tab === 6 && <AdminInfo /> }
              </SimpleBarReact>
            </div>
          </div>
      }
    </div>
  );
}
  
export default Admin;

